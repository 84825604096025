import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Acerca = ({ data }) => {
  // eslint-disable-next-line
  const { header, body } = data.allPagesDataJson.edges[0].node
  return (
    <Layout location="/acerca">
      <SEO title="Quienes Somos" />
      <div>
        <div className="acerca-header">
          <h1 className="title text-center">{header.title}</h1>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12 acerca-content">
              {body.map(block => {
                return (
                  <div key={block.title}>
                    <h3 className="mt-5 sub-title">{block.title}</h3>
                    {block.content.map((element, i) => {
                      if (element.type === "p") {
                        return (
                          <p key={`${block.title}-p-${i}`}>{element.text}</p>
                        )
                      }
                      if (element.type === "ul") {
                        return (
                          <ul key={`${block.title}-ul-${i}`}>
                            {element.list.map((listItem, index) => (
                              <li key={`${block.title}-list-item-${index}`}>
                                {listItem}
                              </li>
                            ))}
                          </ul>
                        )
                      }
                    })}
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    allPagesDataJson(filter: { route: { eq: "acerca" } }) {
      edges {
        node {
          header {
            title
            bg {
              childImageSharp {
                fluid {
                  src
                }
              }
            }
          }
          body {
            title
            content {
              list
              text
              type
            }
          }
        }
      }
    }
  }
`

export default Acerca
